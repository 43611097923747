/**
 * Check if a value exists in an enumerable
 * @param   {any}       type    The enumerable
 * @param   {unknown}   value   The value
 * @returns {boolean}           Whether or not the value exists in the enumerable
 * @hidden
 */
export const existValueInEnum = (type: any, value: unknown): boolean => {
  return (
    Object.keys(type)
      .filter((k) => isNaN(Number(k)))
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      .filter((k) => type[k] === value).length > 0
  );
};

/**
 * Verify whether or not a value is empty
 * @param   {string}    value   The value to check
 * @returns {boolean}           Whether or not a value is empty
 */
export const isEmpty = (value: string): boolean => {
  return value === '';
};

/**
 * Verify whether or not a value is 'nil'
 * @param   {number}    value   The value to check
 * @returns {boolean}           Whether or not the value is null
 */
export const isNil = (value: number): boolean => {
  return value === 0;
};

/**
 * Verify whether or not a value is 'null'
 * @param   {unknown}   value   The value to check
 * @returns {boolean}           Whether or not the value is null
 */
export const isNull = (value: unknown): boolean => {
  return typeof value === null || value === null;
};

/**
 * Verify whether or not a value is 'undefined'
 * @param   {unknown}   value   The value to check
 * @returns {boolean}           Whether or not the value is 'undefined'
 */
export const isUndefined = (value: unknown): boolean => {
  return typeof value === undefined || value === undefined;
};

/**
 * Verify whether or not a value is 'null' or 'undefined'
 * @param   {unknown}   value   The value to check
 * @returns {boolean}           Whether or not the value is 'null' or 'undefined'
 */
export const isNullOrUndefined = (value: unknown): boolean => {
  return isNull(value) || isUndefined(value);
};

/**
 * Verify whether or not a value is 'null' or 'undefined'
 * @param   {string}    value   The value to check
 * @returns {boolean}           Whether or not the value is 'null' or 'undefined'
 */
export const isNullOrUndefinedOrEmpty = (value: string): boolean => {
  return isNull(value) || isUndefined(value) || isEmpty(value);
};

/**
 * Verify whether or not a value is 'null' or 'undefined'
 * @param   {number}    value   The value to check
 * @returns {boolean}           Whether or not the value is 'null' or 'undefined'
 */
export const isNullOrUndefinedOrNil = (value: number): boolean => {
  return isNull(value) || isUndefined(value) || isNil(value);
};

/**
 * Whether or not a string starts with a given string
 * @param   {string}  input     The string to validate
 * @param   {string}  search    The keyword to be used in the validation
 * @returns {boolean}           Whether or not a string starts with a given string
 * @hidden
 */
export const startsWith = (input: string, search: string): boolean => {
  return input.substr(0, search.length) === search;
};

/**
 * Returns whether or not a given value is 'true'
 * @param {boolean|string}  value   The value to check whether or not it is true
 * @returns {boolean}               Whether or not the value is true
 */
export function isTrue(value: boolean | string): boolean {
  return value !== undefined && value !== null && value.toString().toLowerCase() === 'true';
}

/**
 * Return whether or not a given value is 'false'
 * @param {boolean|string}  value   The value to check whether or not it is false
 * @returns {boolean}               Whether or not the value is false
 */
export function isFalse(value: boolean | string): boolean {
  return value !== undefined && value !== null && value.toString().toLowerCase() === 'false';
}

/**
 * Logical XOR
 * @param {boolean} a The first condition
 * @param {boolean} b The second condition
 * @returns  {boolean} XOR
 */
export const xor = (a: boolean, b: boolean): boolean => (a && !b) || (!a && b);
