import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ConfigObject } from '../classes/Config';

type ADSGlobal = { config?: ConfigObject; pipeline?: Map<string, string> };

export type WindowGlobal = Window & typeof globalThis & { AppInsights: ApplicationInsights; ArmDesignSystem: ADSGlobal };

export enum Languages {
  EN = 'en',
}

export type LanguageEn = 'en';

export type Language = LanguageEn;

export enum Positions {
  BOTTOM = 'bottom',
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
}

export type PositionBottom = 'bottom';
export type PositionTop = 'top';
export type PositionLeft = 'left';
export type PositionRight = 'right';

/**
 * Describes an position type.
 * @docs
 * @global
 */
export type Position = PositionBottom | PositionTop | PositionLeft | PositionRight;

export enum StandardSize {
  EXTRA_SMALL = 'xs',
  SMALL = 'sm',
  MEDIUM = 'md',
  LARGE = 'lg',
  EXTRA_LARGE = 'xl',
}

export type StandardSizeXS = 'xs';
export type StandardSizeSM = 'sm';
export type StandardSizeMD = 'md';
export type StandardSizeLG = 'lg';
export type StandardSizeXL = 'xl';

/**
 * Describes an standard size type.
 * @docs
 * @global
 */
export type StandardSizeType = StandardSizeXS | StandardSizeSM | StandardSizeMD | StandardSizeLG | StandardSizeXL;

export enum Themes {
  DEFAULT = 'default',
  DARK = 'dark',
  LIGHT = 'light',
}

export type ThemeDefault = 'default';
export type ThemeDark = 'dark';
export type ThemeLight = 'light';

/**
 * Describes an theme type.
 * @docs
 * @global
 */
export type Theme = ThemeDefault | ThemeDark | ThemeLight;

export enum Directions {
  LTR = 'ltr',
  RTL = 'rtl',
}

export type DirectionLTR = 'ltr';
export type DirectionRTL = 'rtl';

/**
 * Describes an direction type.
 * @docs
 * @global
 */
export type Direction = DirectionLTR | DirectionRTL;

export enum ButtonTypes {
  BUTTON = 'button',
  RESET = 'reset',
  SUBMIT = 'submit',
}

export type ButtonTypeButton = 'button';
export type ButtonTypeReset = 'reset';
export type ButtonTypeSubmit = 'submit';

/**
 * Describes an button type.
 * @docs
 * @global
 */
export type ButtonType = ButtonTypeButton | ButtonTypeReset | ButtonTypeSubmit;

export enum ButtonLevels {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export type ButtonLevelPrimary = 'primary';
export type ButtonLevelSecondary = 'secondary';
export type ButtonLevelTertiary = 'tertiary';

/**
 * Describes an button level.
 * @docs
 * @global
 */
export type ButtonLevel = ButtonLevelPrimary | ButtonLevelSecondary | ButtonLevelTertiary;

export enum ButtonSizes {
  DEFAULT = 'default',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export type ButtonSizeDefault = 'default';
export type ButtonSizeMedium = 'medium';
export type ButtonSizeSmall = 'small';

/**
 * Describes an button size.
 * @docs
 * @global
 */
export type ButtonSize = ButtonSizeDefault | ButtonSizeMedium | ButtonSizeSmall;

export enum Orientations {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export type OrientationVertical = 'vertical';
export type OrientationHorizontal = 'horizontal';

/**
 * Describes an orientation type.
 * @docs
 * @global
 */
export type Orientation = OrientationVertical | OrientationHorizontal;

export enum KeyTypes {
  ENTER = 'Enter',
  ESCAPE = 'Escape',
}

export type KeyTypeEnter = 'Enter';
export type KeyTypeEscape = 'Escape';

export type KeyType = KeyTypeEnter | KeyTypeEscape;

export enum Breakpoints {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XXL = 'xxl',
}

export type BreakpointXS = 'xs';
export type BreakpointSM = 'sm';
export type BreakpointMD = 'md';
export type BreakpointLG = 'lg';
export type BreakpointXL = 'xl';
export type BreakpointXXL = 'xxl';

/**
 * Describes an breakpoint type.
 * @docs
 * @global
 */
export type Breakpoint = BreakpointXS | BreakpointSM | BreakpointMD | BreakpointLG | BreakpointXL | BreakpointXXL;
