import { forceUpdate } from '@stencil/core';
import { isNullOrUndefined, isNullOrUndefinedOrEmpty } from 'utils/collection';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18next, { InitOptions, TOptions } from 'i18next';

/**
 * The i18n default initialisation options
 */
export const defaultOptions: InitOptions = {
  debug: false,
  initImmediate: true,
  fallbackLng: 'en',
  load: 'currentOnly',
  lowerCaseLng: true,
  defaultNS: 'translation',
  detection: {
    order: ['htmlTag'],
  },
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    crossDomain: true,
  },
};

/**
 * Class representing a dictionary
 * @hidden
 */
class DictionaryClass {
  /**
   * Components that will be updated (rendered) when the Dictionary loads
   */
  registeredComponents: any[] = [];

  /**
   * Initialise i18next
   * @param   {InitOptions}             i18nextOptions    The i18next init options object
   * @returns {Promise<void | string>}                    The outcome of the initialisation
   * @hidden
   */
  async init(i18nextOptions: InitOptions): Promise<void | string> {
    const options = { ...defaultOptions, ...i18nextOptions };
    const i18nextLocale = i18next.use(LanguageDetector);
    if (typeof window !== 'undefined') {
      await import('i18next-http-backend').then((module) => {
        i18nextLocale.use(module.default);
      });
    }
    return new Promise((resolve, reject) => {
      void i18nextLocale
        .init(options, (err: string) => {
          if (!isNullOrUndefinedOrEmpty(err)) {
            return reject(err);
          }
          return resolve();
        })
        .then(() => {
          this.registeredComponents.map((component, i) => {
            if (!isNullOrUndefined(component)) {
              forceUpdate(component);
            } else {
              this.registeredComponents.splice(i, 1);
            }
          });
        });
    });
  }

  /**
   * Return the translation for a string
   * @param   {string}              fallback    The translation fallback
   * @param   {string}              key         The string that needs translation
   * @param   {string | TOptions}   options     Additional translation options
   * @returns {string}                          The translation for a string
   */
  get(fallback: string, key: string, options?: string | TOptions): string {
    return i18next.exists(key) ? i18next.t(key, options) : fallback;
  }

  /**
   * Registers components to be updated when the Dictionary loads
   * @param {any} component The component to be registered for update
   */
  registerComponent(component: any): void {
    this.registeredComponents.push(component);
  }
}

export const Dictionary = /*@__PURE__*/ new DictionaryClass();
